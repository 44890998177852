import { EventEmitter, Injectable, Output, inject } from '@angular/core';
import {Location} from '@angular/common';
import {ConfigService} from './configService';
import {IMenu, IMenuDescItem} from '../../oex-ui-kit/components/header/header';
import {AuthService} from './authService';
import {PartnersDirectoryService} from './partnersDirectory';
import {DialogService} from './dialogService';
import {ROUTE_MARKETPLACE} from './global-types';
import {ICompany} from './api.types';

@Injectable({
  providedIn: 'root'
})
export class MenuService {
  private auth = inject(AuthService);
  private ds = inject(DialogService);
  private location = inject(Location);
  private pd = inject(PartnersDirectoryService);

  update = new EventEmitter<void>();

  get items(): IMenu[] {
    if (this.location.path().startsWith('/' + ROUTE_MARKETPLACE)) {
      return this.getMarketplaceMenu();
    }
    return this.pd.enabled ? this.getPDMenu() : this.getOEXMenu();
  }

  getMarketplaceMenu() {
    const portalUrl = '/' + ROUTE_MARKETPLACE + '/portal';
    const m: IMenu[] = [
      {
        id: 'Certification',
        title: 'Certification',
        externalUrl: 'https://www.intersystems.com/support-learning/learning-services/certification-program/'
      },
      {
        id: 'Learning',
        title: 'Learning',
        externalUrl: 'https://learning.intersystems.com/'
      },
      {
        id: 'Documentation',
        title: 'Documentation',
        externalUrl: 'https://docs.intersystems.com/'
      },
      {
        id: 'FHIR Innovation',
        title: 'FHIR Innovation',
        url: '/' + ROUTE_MARKETPLACE + '/solutions',
        query: { 'work-with': 'InterSystems-FHIR', 'sort': 'd.desc', fromMenu: '1'}
      },
      {
        id: 'profile',
        title: 'Profile',
        titleDesc: this.auth.getUserName(),
        showAvatar: true,
        hideOnMobile: true,
        condition: () => this.auth.isLogged,
        desc: this.auth.getUserName(),
        items: [
          {
            title: 'My Buyer Portal',
            url: portalUrl,
            condition: () => this.auth.isLogged
          },
          {
            title: 'Organisation Profile',
            url: portalUrl + '/company',
            condition: () => this.auth.isLogged
          },
          {
            title: 'Recommendations',
            url: portalUrl + '/recommendations',
            condition: () => this.auth.isLogged
          },
          {
            title: 'Saved Searches',
            url: portalUrl + '/searches',
            condition: () => this.auth.isLogged
          },
          {
            title: 'Favorites',
            url: portalUrl + '/favorites',
            condition: () => this.auth.isLogged
          },
          {
            title: 'My Inquiries',
            url: portalUrl + '/inquiries',
            condition: () => this.auth.isLogged
          },
          {
            title: 'My Profile',
            url: portalUrl + '/profile',
            condition: () => this.auth.isLogged
          },
          {
            title: 'Contact InterSystems',
            url: portalUrl + '/contact',
            condition: () => this.auth.isLogged
          },
          {
            title: 'Log out',
            style: 'link',
            separator: true,
            condition: () => this.auth.isLogged,
            click: () => this.doLogout()
          }
        ]
      }
    ];
    return m;
  }

  getProfileItem() {
    const profile: IMenu = {
      id: 'profile',
      title: 'Profile',
      titleDesc: this.auth.getUserName(),
      showAvatar: true,
      hideOnMobile: true,
      condition: () => this.auth.isLogged,
      desc: this.auth.getUserName(),
      buttons: [
        {
          id: 'logout',
          title: 'Log out',
          condition: () => this.auth.isLogged,
          click: () => this.doLogout()
        }
      ],
      items: [
        ...(this.pd.enabled ? this.getProfileItemsPD() : this.getProfileItemsOEX()),
        {
          title: 'Log out',
          style: 'link',
          separator: true,
          condition: () => this.auth.isLogged,
          click: () => this.doLogout()
        }
      ]
    }
    return profile;
  }

  private getPDMenu() {
    const m: IMenu[] = [
      {
        id: 'find-a-partner',
        title: 'Find a partner',
        url: '/companies',
        condition: () => this.pd.enabled
      },
      {
        id: 'find-a-product',
        title: 'Find a product',
        url: '/solutions',
        condition: () => this.pd.enabled
      },
      {
        id: 'for-partners',
        title: 'For Partners',
        condition: () => this.pd.enabled,
        items: [
          {
            title: 'About InterSystems Partner Portal',
            url: '/about'
          },
          {
            title: 'Become a Partner',
            url: '/become-a-partner'
          }
        ],
        buttons: [
          {
            id: 'login-pd',
            title: 'Login InterSystems Partner Portal',
            condition: () => !this.auth.isLogged,
            click: () => this.gotoLoginForm()
          }
        ]
      },
      this.getProfileItem()
    ];
    return m
  }

  private getOEXMenu() {
    const m: IMenu[] = [
      {
        id: 'apps',
        title: 'Applications',
        items: [
          {
            title: 'Featured applications',
            url: '/',
            query: {fromMenu: 2}
          },
          {
            title: 'All applications',
            url: '/',
            query: {fromMenu: 1}
          },
          {
            title: 'Tags',
            url: '/tags'
          },
          {
            title: 'Ideas',
            externalUrl: 'https://ideas.intersystems.com/?status=7058311167029975399&sort=recent'
          }
        ],
        thirdSection: {
          banner: {
            title: 'Share your application with our community',
            link: 'Submit Application',
            click: () => this.onSubmitApplication()
          },
          columns: [
            {
              title: 'Most popular applications',
              items: ConfigService.MENU?.apps?.map(app => {
                return {
                  title: app.name,
                  url: `/package/${app.nws}`
                };
              })
            },
            {
              title: 'Categories',
              items: ConfigService.CATEGORIES.map(cat => {
                return {
                  title: cat.Name,
                  url: '/',
                  query: {categories: cat.Name, fromMenu: 1}
                };
              })
            },
            {
              title: 'Applications build with',
              items: [
                {
                  title: 'IPM',
                  url: '/',
                  query: {zpm: 1, fromMenu: 1}
                },
                {
                  title: 'Docker',
                  url: '/',
                  query: {docker: 1, fromMenu: 1}
                },
                {
                  title: 'Python',
                  url: '/',
                  query: {python: 1, fromMenu: 1}
                },
                {
                  title: 'AI',
                  url: '/',
                  query: {AI: 1, fromMenu: 1}
                },
                {
                  title: 'ML',
                  url: '/',
                  query: {ML: 1, fromMenu: 1}
                },
                {
                  title: 'Demo',
                  url: '/',
                  query: {demo: 1, fromMenu: 1}
                }
              ]
            }
          ]
        }
      },
      {
        id: 'contest',
        title: 'Contest',
        isIndicator: this.isContestActive(),
        items: [
          {
            title: 'Current',
            url: '/contest/current'
          },
          {
            title: 'History',
            url: '/contests',
            query: {archive: 1},
          },
          {
            title: 'List',
            url: '/contests',
            condition: () => this.auth.isAdmin
          },
          {
            title: 'About Contests',
            url: '/about-contests',
          }
        ],
        thirdSection: {
          columns: [
            {
              title: 'The most popular contests',
              isGrid: true,
              items: ConfigService.MENU?.contests?.map(c => {
                return {
                  title: c.name,
                  url: `/contest/${c.id}`
                };
              })
            }
          ]
        }
      },
      {
        id: 'explore',
        title: 'Explore',
        items: [
          {
            title: 'Open Exchange Members',
            url: '/members'
          },
        ],

        thirdSection: {
          columns: [
            {
              title: 'What’s new',
              items: [
                {title: 'Applications releases', url: '/news'}
              ]
            },
            {
              title: 'Knowledge base',
              items: [
                {
                  title: 'Documentation',
                  externalUrl: this.pd.enabled ? 'https://docs.partner.intersystems.com/' : 'https://docs.openexchange.intersystems.com/'
                },
                {title: 'Developer Community', externalUrl: 'https://community.intersystems.com/'}
                // {title: 'About Open Exchange', url: '/about'}
              ]
            },
            {
              title: 'Help center',
              items: [
                {title: 'Contact us', externalUrl: 'https://www.intersystems.com/who-we-are/contact-us/'},
                {title: 'Report an issue', externalUrl: 'https://github.com/intersystems-community/openexchange/issues'}
              ]
            }
          ],
          banner: {
            title: 'How to publish IPM',
            link: 'Learn',
            externalUrl: 'https://youtu.be/havPyPbUj1I'
          }
        }
      },
      this.getProfileItem()
    ]
    return m;
  }

  private getProfileItemsOEX() {
    const items: IMenuDescItem[] = [
      {title: 'My Public Profile', url: `/user/${this.auth.getUserName()}/${this.auth.getUserKey()}`},
      {title: 'My Applications', url: `/portal/products`},
      /*{
        title: 'Applications',
        url: `/user/${this.auth.getUserName()}/${this.auth.getUserKey()}`,
        condition: () => !!this.auth.info?.solutions
      },*/
      {title: 'Statistics', url: `/portal/statistics`},
      {title: 'Bookmarks', url: `/portal/bookmarks`},
      {title: 'Reviews', url: `/portal/reviews`},
     /* {
        title: 'Awards',
        url: `/user/${this.auth.getUserName()}/${this.auth.getUserKey()}`,
        condition: () => !!this.auth.info?.rewards
      },*/
      {title: 'Notification Settings', url: `/portal/profile/subscriptions`},
      {title: 'App quality management', url: `/portal/app-quality`, condition: () => this.auth.isModerator},
      ...(this.auth.isProfileOwner ? [{title: 'Dev Directory Portfolio', url: '/portal/profile-developer'}] : []),
      {title: 'Edit Profile', url: '/portal/profile/personal'},
    ];

    return items;
  }

  private getProfileItemsPD() {
    let c: ICompany | undefined = undefined;
    const companies = this.auth.getUserCompanies();
    if (companies?.length) {
      c = companies[0];
    }

    const items: IMenuDescItem[] = [
     /* {
        title: 'Company',
        url: `/company/${c?.nws}`,
        condition: () => !!c
      },
      {
        title: 'Products',
        url: `/company/${c?.nws}`,
        query: {tab: 'solutions'},
        condition: () => !!c
      },
      {
        title: 'Services',
        url: `/company/${c?.nws}`,
        query: {tab: 'services'},
        condition: () => !!c
      },*/
      // Without company
      {title: 'Enroll as Partner', url: `/portal/company`, condition: () => !c},
      {title: 'Join a Partner', url: `/portal/join`, condition: () => !c},

      // With company
      {title: 'My public profile', url: `/company/${c?.nws}`, condition: () => !!c},
      {title: 'Edit profile', url: '/portal/profile/personal'},
      {title: 'Overview', url: '/portal'},
      {title: 'Partner Benefits', url: '/portal/benefits', condition: () => !!c},
      {
        title: 'Partner Directory profile',
        url: `/portal/company/${c?.nws}`,
        condition: () => !!c
      },
      {title: 'Members', url: '/portal/members', condition: () => !!c},
      {title: 'Get Certified', url: '/portal/certification', condition: () => !!c},
      {title: 'Marketing Tools', url: '/portal/marketing-tools', condition: () => !!c},
      {title: 'Startup Program', url: '/portal/startup', condition: () => !!c},

      // Always visible
      {title: 'Reviews', url: '/portal/reviews'},
      {title: 'Messages', url: '/portal/messages'},
      {title: 'Edit Profile', url: '/portal/profile/personal'},
      {title: 'Contact InterSystems', url: '/portal/contacts'}
    ];
    return items;
  }

  private async doLogout() {
    await this.auth.logout();
    this.update.emit();
  }

  /**
   * Logs in with IS oAuth
   */
  private gotoLoginForm() {
    this.auth.isLoginFromMenu = true;
    this.auth.login();
  }

  private isContestActive() {
    return !!(ConfigService.contest.id && !ConfigService.isContestEnded());
  }

  private onSubmitApplication() {
    this.ds.showNewAppDialog();
  }
}

