import {CanActivateFn, ResolveFn, Router} from '@angular/router';
import {ISidebarItem} from '../../../oex-ui-kit/components/sidebar/sidebar.component';
import {of} from 'rxjs/internal/observable/of';
import {inject} from '@angular/core';
import {AuthService} from '../authService';

export const oexSidebarItems: ResolveFn<ISidebarItem[]> = () => {
  const auth = inject(AuthService);
  const items: ISidebarItem[] = [
    {label: 'My Applications', url: 'products'},
    {label: 'Statistics', url: 'statistics'},
    {label: 'Bookmarks', url: 'bookmarks'},
    {label: 'Reviews', url: 'reviews'},
    {label: 'Pull Requests', url: 'pull-requests'},
    {label: 'Notification Settings', url: 'profile/subscriptions'},
    ...(auth.isModerator ? [{label: 'App Quality Management', url: 'app-quality'}] : []),
    ...(auth.isProfileOwner ? [{label: 'Dev Directory Portfolio', url: 'profile-developer'}] : []),
    {label: 'Edit Profile', url: 'profile/personal'},
    {label: 'Logout', separator: true, style: 'link', click: () => auth.logout()},
    // {label: 'Contest and Awards', url: 'contest'},
  ];

  return of(items);
}
